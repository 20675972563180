/*******************************
         Site Overrides
*******************************/
i.fitted.icon {
    margin: 0em 1em 0em 1em !important;
}

i.icon.arrowDown {
margin: 0.5em 0em 0em 0em;
 text-shadow: 2px 2px 2px #000000;
}

.slick-active i.icon {
    opacity: 1 !important;
}

.slick-dots li i {
    opacity: 0.2 !important;
}

.slick-dots li i:hover {
    opacity: 1 !important;
}

i.icon.plus {
    color: @white;
}
