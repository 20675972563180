/*******************************
         Site Overrides
*******************************/
.ui.button.addEstateButton {
    margin: 0em 0em 1em 0em;
}

.ui.button.catalogLink {
    background-color: Transparent;
    background-repeat:no-repeat;
    overflow: hidden;
    outline:none;
    border:  2px solid white;
    display:  inline-block;
    float:  none;
    text-align:  center;
    color:  #fff;
}

.ui.button.catalogLink:hover{
    color:  #fff;
    background:  rgba(255, 255, 255, 0.2);
}

.ui.button.social {
    margin-bottom: 1em;
}

.textButton {
  background: none!important;
  border: none !important;
  padding: 0!important;
  color: #069 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  text-align: left !important;
  font-weight: normal !important;
  margin: 0em 0em 0em 1em !important;
  display: inline !important;
  width: 90% !important;
}

@media only screen and (max-width: 992px) {
    .textButton {
        width: 85% !important;
    }
}

.textFooterButton {
  background: none!important;
  border: none !important;
  padding: 0!important;
  color: rgba(255, 255, 255, 0.5) !important;
  text-decoration: none !important;
  cursor: pointer !important;
  text-align: left !important;
  font-weight: normal !important;
}

.textFooterButton:hover {
  color: #FFFFFF !important;
}