/*******************************
         Site Overrides
*******************************/

.ui.text.fluid.container.referenceBlock {
    margin: 1em 0em 0em 0em;
}

.ui.container.logo {
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    .logo-img {
        background: white;
        max-width: 50px;
    }

    .ui.header {
        margin-top: 0px !important;
        font-size: 1.2em !important;

        .sub.header {
            font-size: 1em !important;
        }
    }
}


.ui.container.homeIntro {
    padding: 2em 0em 2em 0em;
}

.ui.container.carousel {
   
    text-align: center;
    z-index: 2;
    position: relative;
}

.ui.container.aboutText {
    font-size: 1.3em;
    margin: 0em 0em 1em 0em;
}

.ui.container.catalogButton {
    margin: 10em 0em 0em 0em;
}

.ui.container.introServices {
    margin: 3em 0em 0em 0em;
}

.ui.text.container.shortDescription {
    height: 15em;
    overflow: hidden;
}

.ui.text.container.shortDescription:after {
  position: absolute;
  bottom: 0;  
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top,
     rgba(255,255,255, 1) 20%, 
     rgba(255,255,255, 0) 80%
  );
  pointer-events: none; /* so the text is still selectable */
}

@media screen and (max-width: 320px) {
    .ui.text.container.shortDescription:after {
        position: absolute;
        bottom: 0;  
        height: 100%;
        width: 100%;
        content: "";
        background: linear-gradient(to top,
        rgba(255,255,255, 1) 20%, 
        rgba(255,255,255, 0) 80%
  );
  pointer-events: none; /* so the text is still selectable */
}
}

@media screen and (max-width: 768px) and (min-width: 321px) {
    .ui.text.container.shortDescription:after {
        position: absolute;
        bottom: 0;  
        height: 100%;
        width: 90%;
        content: "";
        background: linear-gradient(to top,
        rgba(255,255,255, 1) 20%, 
        rgba(255,255,255, 0) 80%
  );
  pointer-events: none; /* so the text is still selectable */
}
}

@media screen and (max-width: 992px) and (min-width: 769px) {
    .ui.text.container.shortDescription:after {
        position: absolute;
        bottom: 0;  
        height: 100%;
        width: 97%;
        content: "";
        background: linear-gradient(to top,
        rgba(255,255,255, 1) 20%, 
        rgba(255,255,255, 0) 80%
  );
  pointer-events: none; /* so the text is still selectable */
}
}

@media screen and (max-width: 1280px) and (min-width: 992px) {
    .ui.text.container.shortDescription:after {
        position: absolute;
        bottom: 0;  
        height: 100%;
        width: 85%;
        content: "";
        background: linear-gradient(to top,
        rgba(255,255,255, 1) 20%, 
        rgba(255,255,255, 0) 80%
  );
  pointer-events: none; /* so the text is still selectable */
}
}

@media screen and (max-width: 1920px) and (min-width: 1281px) {
    .ui.text.container.shortDescription:after {
        position: absolute;
        bottom: 0;  
        height: 100%;
        width: 95%;
        content: "";
        background: linear-gradient(to top,
        rgba(255,255,255, 1) 20%, 
        rgba(255,255,255, 0) 80%
  );
  pointer-events: none; /* so the text is still selectable */
}
}

.referenceContainer {
    margin-top: 2em !important;
}
