/*******************************
         Site Overrides
*******************************/
.ui.inverted.segment.homepage {
    min-height:120px;
}

.ui.inverted.segment.other {
    min-height:120px;
}

.ui.inverted.segment.homepageMobile {
    min-height:180px;
}

.ui.inverted.segment.otherMobile {
    min-height:180px;
}

.ui.inverted.vertical.segment.footer {
    padding: 5em 0em;
    margin: 3em 0em 0em 0em;
    flex-shrink: 0;
}

.ui.vertical.segment.wrapper {
padding: 8em 0em;
}

.ui.vertical.segment.carousel {
    margin: 0em 0em 3em 0em;

}

@media only screen and (max-width: 500px) {
  .ui.vertical.segment.carousel {
  margin: 0em 0em 3em 3em;
}
}

@media only screen and (max-width: 761px) and (min-width: 501px)  {
  .ui.vertical.segment.carousel {
  margin: 0em 0em 3em 5em;
}
}

.ui.vertical.segment.carouselWrapper {
    position: relative;
    background: #000000;    
    overflow: hidden;
    padding: 4em 0em 4em 0em;
}

.ui.vertical.segment.carouselWrapper:after {
    background: #ADA996;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-image: url('/carback.jpg');
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.3;
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
     background-position: center center;
}

.ui.vertical.segment.aboutWrapper {
    position: relative;
    background: #000000;    
    overflow: hidden;
    height: 500px;
    margin: 3em 0em 0em 0em;
}

.ui.vertical.segment.aboutWrapper:before {
    background-image: url('/aboutDiv.jpg');
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
     background-position: center center;
}

.ui.inverted.segment.homeBox {

}

.ui.vertical.segment.header {
    margin: 3em 0em 3em 0em;
}

.ui.segment.contactList {
    max-width: 30em;
    height: 200px;
    margin-top: 0px !important;

    .ui.header *{  
        margin-top: 0.3em;       
    }
}

.ui.inverted.segment.segmentPrice {
  display: table;
  margin: 0em auto 2em auto;
}

.ui.segment.aboutCounter {
    margin: 0em;
}

.ui.vertical.segment.about {
    margin-top: 3em !important;
    padding-top: 3em !important;
}

.contactHeader {
    margin: 3em 0em 3em 0em !important;
}

.segmentEstates {
    padding: 1em !important;
    border-radius: 0em !important;
}

.attachedSegment {
    margin-bottom: 2em !important;
}