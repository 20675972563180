/*******************************
         Site Overrides
*******************************/
.aboutPhoto {
    margin: 0em auto 1em auto;
}

.aboutSign {
    margin: 1em 0em 0em 0em;
}

.ui.image.broker {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.footerMark {
  margin-left: 10px;
}

@media only screen and (min-width: 992px) {
  .contactPhoto {
    margin-bottom: -198px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .contactPhoto {
    margin-bottom: -210px !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1999px) {
  .contactPhoto {
    margin-bottom: -300px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .contactPhoto {
    margin-bottom: -300px!important;
  }
}