/*******************************
         Site Overrides
*******************************/
.ui.form.editRefForm {
    margin: 0em 0em 3em 0em;
}

.contactForm {
    text-align: left !important;
}

.termsCheckbox {
    display: inline !important;
}

.termsCheckbox div {
    padding-top: 10px;
}