/*******************************
         Site Overrides
*******************************/
html, body{
    height:100%;
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content {
   -ms-flex: 1 1 auto !important;
   -webkit-flex: 1;
   flex: 1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .content {
        flex-grow:1;
        flex: 1 1 auto !important;
   }
}

.slider .ui.text.container {
  max-width: 70% !important;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-prev.slick-arrow {
  padding-left: 8rem;
  padding-right: calc(8rem + 20px);
  display: inline-block;
  height: 100%;
  z-index: 32;
}

.slick-next.slick-arrow {
  padding-left: 8rem;
  padding-right: calc(8rem + 20px);
  display: inline-block;
  height: 100%;
  z-index: 32;
}

@media only screen and (max-width: 767px) {
  .slick-prev.slick-arrow {
    padding-left: 4rem;
    padding-right: calc(4rem + 20px);
  }

  .slick-next.slick-arrow {
    padding-left: 4rem;
    padding-right: calc(4rem + 20px);
  }
}

.slick-dots {
  bottom: 10px !important;
}

.plus {
  color: @red;
  font-size: 1em;
}

.socialNetwork {
  display: inline;
}

.telNumber {
  margin: 0em 0.5em 0em 0em;
  font-size: 1.1em;
  color: #FFFFFF !important;
}

.carouselText {
  font-size: 1.2em;
  color: #FFFFFF;
  text-shadow: 2px 2px 2px #000000;
}

.aboutQuote {
  font-size: 2em;
  color: #000000;
}

.aboutSubtitle {
  font-size: 1.3em;
  margin: 0em 0em 0em 0em;
}

@media only screen and (max-width: 767px) {
  .carouselText {
  font-size: 1em;
  color: #FFFFFF;
  text-shadow: 2px 2px 2px #000000;
}
}

.carInnerText {
  color: @grey;
  text-shadow: 2px 2px 2px #000000;
}

.carInnerText2 {
  color: @grey;
  text-shadow: 2px 2px 2px #000000;
  margin: 0em 0em 0em 1em !important;
}

.serviceItemBullet {
  margin: 0em 0em 1em 0em;
}

.contactAbout {
  margin: 3em 0em 0em 0em;
}

.app-image-gallery {
  margin-bottom: 2em;
}

.image-gallery-icon:hover {
  color: @red !important; 
    }

.image-gallery-icon:focus {
  color: @red !important; 
    }

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
  border: 2px solid @red !important;
}

.image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
  background: @red !important;
}

.refDeleteButton {
  margin: 1em 0em 0em 0em;
}

.map {
  height: 400px;
}

.socialButtons {
  margin: 1em 0em 0em 0em;
}


.videoCheckbox {
  margin : 1em 0em 1em 0em;
}


.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 15px;
  margin-bottom: 10px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.label {
    display: block;
    margin: 0em 0em 0.28571429rem 0em;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.92857143em;
    font-weight: bold;
    text-transform: none
}

.referenceText {
  font-size: 1.3em;
}


.sincerelyText {
  font-size: 1.5em;
  padding-top: 2em;
}

.customlink {
  color: rgba(0, 0, 0, 0.65) !important;
  text-decoration: underline !important;
}

.customphonelink {
  color: rgba(0, 0, 0, 0.65) !important;
}

.customphonemenulink {
  color: #FFFFFF !important;
}

#customFooterLink:hover {
  color: #FFFFFF !important;
}

.aboutMe {
  margin-top: 1em;
}

.contact-col{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}






