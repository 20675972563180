/*******************************
         Site Overrides
*******************************/
.ui.inverted.header.telNumber{
    margin: 0em 0.5em 0em 0em;
}

.ui.inverted.header.footer{
    margin-left: 0.1em!important;
    margin-top: 0.9em!important;
}

.ui.header .sub.header.squares {
    margin: 1em 0em 0em 0em;
}

.ui.header.car {
    color: #FFFFFF;
    margin: 0 auto;
    display: table;
    text-shadow: 2px 2px 2px #000000;
}

.ui.header.introServicesHeader {
    text-shadow: 1px 1px 1px #000000;
}