/*******************************
         Site Overrides
*******************************/
.ui.grid.contactPage {
    margin: 0em 0em 5em 0em;
}

.ui.grid.login {
    margin: 5em 0em 5em 0em;
}

.ui.grid.adminRefGrid {
    margin: 0em 0em 2em 0em;
}

.row.estateListHeader {
    margin: 1em 0em 0em 0em !important;
}

.row.aboutRow{
    padding-bottom: 0em !important;
    margin-bottom: 3em;
}